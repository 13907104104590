import React, { Component } from 'react';
import { MapContainer, TileLayer, LayersControl, ScaleControl, ScaleControlProps } from 'react-leaflet'
import Legend from './Legend'
import Logo from './Logo'
import GeoJSONUrl from './GeoJSONUrl'
import StatefulUrl, { getUrlState } from './StatefulUrl';
import * as L from "leaflet";

export class Home extends Component {
    static displayName = Home.name;

    onEachFeature(feature, layer) {
        if (feature.properties && feature.properties.NAVN) {
            if (feature.properties.NAVN === "HovedruteLenke") {
            }
            else if (feature.properties.NAVN === "Sykkelstamvei") {
                layer.bindPopup(feature.properties.NAVN);
            }
            else if (feature.properties.RUTENR) {
                var name = "Hovedrute " + feature.properties.RUTENR + " " + feature.properties.NAVN.replace("_", " ");

                if (feature.properties.SYKKEL) {
                    name += " (" + (feature.properties.SYKKEL + "").toLowerCase().replace("retning ", "").replace("retnig ", "") + ")";
                }
                layer.bindPopup(name);
            }
        }
    }

    filterFeature(feature, layer) {
        return feature.geometry.type === "LineString";
    }

    getStyle(feature) {
        var style = { opacity: 0.5, color: "limegreen" };

        if (feature.properties.RUTENR)
            style.weight = 5;
        else if (feature.properties.NAVN === "Sykkelstamvei")
            style.weight = 8;
        else
            style.weight = 2;

        return style;
    }

    render() {
        const defaultState = { latlng: { lat: 58.95070, lng: 5.72455 }, zoom: 13 };

        const mapState = getUrlState() || defaultState;

        const isRetina = L.Browser.retina;
        const tileLayerUrl = isRetina ? "https://sykkelkart.rogalandsyklistforening.no/tile@2x/{z}/{x}/{y}.png" : "https://sykkelkart.rogalandsyklistforening.no/tile/{z}/{x}/{y}.png";

        return (
            <MapContainer center={mapState.latlng} zoom={mapState.zoom}>
                <ScaleControl imperial={false} />
                <Logo position="topleft" url="/logo.png" link="https://rogalandsyklistforening.no/" />
                <StatefulUrl />
                <TileLayer
                    maxZoom="20"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Rogaland Syklistforening'
                    url={tileLayerUrl}
                />
                <LayersControl position="topright">
                    <LayersControl.Overlay name="Sykkelruter Stavanger">
                        <GeoJSONUrl
                            attribution="&copy; Stavanger kommune"
                            url="https://opencom.no/dataset/8113c645-840b-4cb3-adec-ffdcf7d1c07b/resource/4baffcde-f2ec-4cbf-bc20-b0d4999253d1/download/sykkelruter.json"
                            style={this.getStyle}
                            onEachFeature={this.onEachFeature}
                            filter={this.filterFeature}
                        />
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Kommuneplan hovednett for sykkel Nord-Jæren">
                        <GeoJSONUrl
                            attribution="&copy; Stavanger kommune"
                            url="https://opencom.no/dataset/0f0e037e-b5e8-453f-97ca-8ae9be7e523c/resource/7fa800bd-c094-4051-8750-e05e4b0f47ed/download/sykkelnettkartnord-jaeren2016.json"
                        />
                    </LayersControl.Overlay>
                </LayersControl>
                <Legend />
            </MapContainer>
        );
    }
}