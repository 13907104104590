import { useEffect, useRef } from 'react';
import L from 'leaflet';
import { useMapEvents } from 'react-leaflet/hooks';

const roadTypes = [
    {
        name: "Sykkelekspressvei",
        colour: "F95E66",
        dashArray: "",
        width: 5,
        minZoom: 6
    },
    {
        name: "Sykkelvei",
        colour: "F95E66",
        dashArray: "",
        width: 5,
        minZoom: 6
    },
    {
        name: "Sykkelfelt",
        colour: "91C160",
        dashArray: "8,6",
        width: 3,
        minZoom: 8
    },
    {
        name: "Gang- og sykkelvei",
        colour: "E5C499",
        dashArray: "",
        width: 3,
        minZoom: 12
    },
    {
        name: "Uavklart type eller ikke asfaltert",
        colour: "FF0000",
        dashArray: "2,7",
        width: 2,
        minZoom: 17
    },
    {
        name: "Fortau/gangvei/gangfelt",
        colour: "000000",
        dashArray: "1,5",
        width: 2,
        minZoom: 17
    }
];

function Legend() {
    const legendRef = useRef();
    const legendDivRef = useRef();

    const map = useMapEvents({
        zoomend: () => {
            legendDivRef.current.innerHTML = generateLegend(map.getZoom());
        }
    });

    useEffect(() => {
        legendRef.current = L.control({ position: "bottomright" });

        legendRef.current.onAdd = () => {
            legendDivRef.current = L.DomUtil.create("div", "sykkelkart_legend");
            legendDivRef.current.innerHTML = generateLegend(map.getZoom());
            return legendDivRef.current;
        };

        legendRef.current.addTo(map);

        return () => {
            map.removeControl(legendRef.current);
        }
    });

    return null

    function generateLegend(zoomLevel) {
        var labels = [];

        for (let i = 0; i < roadTypes.length; i++) {
            if (zoomLevel >= roadTypes[i].minZoom) {
                var imageUrl = "/api/visuals/renderRoad?width=50&height=" + roadTypes[i].width + "&colour=" + roadTypes[i].colour + "&dashArray=" + roadTypes[i].dashArray;

                labels.push(
                    '<div class="legend_entry"><span style="width: 50px; height: 10px; background-image: url(' + imageUrl + ');"></span> ' +
                    roadTypes[i].name + '</div>'
                );
            }
        }

        return labels.join("\n");
    }
}

export default Legend