import { useEffect, useRef } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';

function Logo(props) {
    const logoRef = useRef();
    const map = useMap();

    useEffect(() => {
        logoRef.current = L.control({ position: props.posision ?? "topleft" });

        logoRef.current.onAdd = () => {
            const div = L.DomUtil.create("div", "sykkelkart_logo");
            div.style = props.style;
            div.innerHTML = "<a target='_new' href='" + props.link + "'><img style='width:100%;height:100%' src='" + props.url + "'/></a>";
            return div;
        };

        logoRef.current.addTo(map);

        return () => {
            map.removeControl(logoRef.current);
        }
    });

    return null
}

export default Logo