import { GeoJSON } from 'react-leaflet/GeoJSON';
import { useEffect, useState } from 'react';

function GeoJSONUrl(props) {
    const [geoJson, setGeoJson] = useState(null);

    useEffect(() => {
        fetch("/api/cors/proxy?url=" + encodeURIComponent(props.url))
            .then(data => data.json())
            .then(data => setGeoJson(data));
    }, []);

    if (geoJson)
        return <GeoJSON data={geoJson} {...props} />
    else
        return null;
}

export default GeoJSONUrl;