import { useEffect } from 'react';
import { useMapEvents } from 'react-leaflet/hooks';

function StatefulUrl() {
    const map = useMapEvents({
        moveend: () => {
            var center = map.getCenter();
            var zoom = map.getZoom();
            window.location.hash = "#" + center.lat.toFixed(5).toString() + "," + center.lng.toFixed(5).toString() + "," + zoom.toString();
        }
    });

    useEffect(() => {
        const state = getUrlState();

        if (state && !isNaN(state.latlng.lat) && !isNaN(state.latlng.lng)) {
            map.setView(state.latlng, state.zoom);
        }
    }, []);

    return null
}

function getUrlState() {
    var lat, lng, zoom;

    if (window.location.hash) {
        var parts = window.location.hash.substring(1).split(",");
        if (parts.length == 3) {
            lat = parseFloat(parts[0]);
            lng = parseFloat(parts[1]);
            zoom = parseInt(parts[2]);

            return { latlng: { lat, lng }, zoom };
        }
    }

    return null;
}

export { StatefulUrl as default, getUrlState }